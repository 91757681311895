@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&family=Roboto:wght@300;400;500;700&display=swap');
body,
html {
  color: #ffffff;
  font-family:'Roboto', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  overflow-x: hidden;
  background: url('./assets/HunterToken-Background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #ffffff;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 600;
  line-height: 1.2em;
}
.cs-primary_font {
  letter-spacing: 0.02em;
}
ul {
  margin: 0 0 25px 0;
  padding-left: 20px;
  list-style: square outside none;
}
ol {
  padding-left: 20px;
  margin-bottom: 25px;
}
cite,
dfn,
em,
i {
  font-style: italic;
}
blockquote {
  margin: 0 15px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;
}
address {
  margin: 0 0 15px;
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
}
a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
button {
  color: inherit;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  text-decoration: none;
  color: #0052ff;
}
table {
  width: 100%;
  margin-bottom: 25px;
}
table th {
  font-weight: 600;
  color: #6c7c8c;
}
table td,
table th {
  border-top: 1px solid #eaeaea;
  padding: 11px 10px;
}
dl {
  margin-bottom: 25px;
}
dl dt {
  font-weight: 600;
}
b,
strong {
  font-weight: 700;
}
pre {
  color: #6c7c8c;
  border: 1px solid #eaeaea;
  font-size: 18px;
  padding: 25px;
  border-radius: 5px;
}
kbd {
  font-size: 100%;
  background-color: #6c7c8c;
  border-radius: 5px;
}
:root {
  scroll-behavior: initial;
}
@media screen and (max-width: 991px) {
  body,
  html {
    font-size: 16px;
    line-height: 1.6em;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
}
.cs-preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;
  height: 100vh;
}
.cs-preloader_bg {
  text-align: center;
  height: 100%;
  width: 100%;
}
.cs-preloader_in {
  width: 120px;
  height: 120px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 28px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.cs-preloader_in:after {
  content: "";
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #fff;
  border-radius: 50%;
  position: absolute;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  left: 50%;
  top: 50%;
  -webkit-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
  margin-left: -60px;
  margin-top: -60px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cs-row_gap_20 {
  margin-left: -10px;
  margin-right: -10px;
}
.cs-row_gap_20 > div {
  padding-left: 10px;
  padding-right: 10px;
}
.cs-light {
  font-weight: 300;
}
.cs-normal {
  font-weight: 400;
}
.cs-medium {
  font-weight: 500;
}
.cs-semi_bold {
  font-weight: 600;
}
.cs-bold {
  font-weight: 700;
}
.cs-extra_bold {
  font-weight: 800;
}
.cs-black {
  font-weight: 900;
}
.cs-radius_3 {
  border-radius: 3px;
}
.cs-radius_5 {
  border-radius: 5px;
}
.cs-radius_7 {
  border-radius: 7px;
}
.cs-radius_10 {
  border-radius: 10px;
}
.cs-body_line_height {
  line-height: 1.5em;
}
.cs-heading_line_height {
  line-height: 1.2em;
}
.cs-font_14 {
  font-size: 14px;
}
.cs-font_16 {
  font-size: 16px;
}
.cs-font_18 {
  font-size: 18px;
}
.cs-font_20 {
  font-size: 20px;
}
.cs-font_22 {
  font-size: 22px;
}
.cs-font_30 {
  font-size: 30px;
}
.cs-font_36 {
  font-size: 36px;
}
.cs-font_42 {
  font-size: 42px;
}
.cs-font_50 {
  font-size: 50px;
}
.cs-font_60 {
  font-size: 60px;
}
.cs-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cs-m0 {
  margin: 0;
}
hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #f2f1ff;
}
.cs-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cs-vertical_middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100%;
}
.cs-vertical_middle_in {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
}
.cs-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cs-white_color,
.cs-white_color_hover:hover {
  color: #fff;
}
.cs-primary_color {
  color: #003057;
}
.cs-accent_color,
.cs-accent_color_hover:hover {
  color: #0052ff;
}
.cs-gradient_color {
  background: linear-gradient(108.18deg, #ea4c89 -12.02%, #4d44c6 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cs-gray_bg {
  background-color: #f4f6f9;
}

.cs-site_header {
  position: relative;
  z-index: 101;
}
.cs-site_header .cs-btn.cs-style6 {
  font-size: 16px;
}
.cs-site-branding {
  display: inline-block;
  max-width: 180px;
}
.cs-site_header.cs-style1 {
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-bottom: 1px solid #7e96a9;
  z-index: 9;
  background-color: #1E2731;
  position: fixed;
  height: 100px;
}
.wallet_connect_Login {
  background-color: #FA9F09;
  font-weight: 700;
  color: #fff;
  border-radius: 10px;
  border: 2px solid #FA9F09;
  padding: 7px 20px;
  font-size: 18px;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}
.cs-toolbox > :not(:first-child) {
  margin-left: 15px;
}
.cs-toolbox_btn {
  border-radius: 5px;
  color: #fefefe;
  font-size: 16px;
  display: inline-block;
  padding: 11px 30px;
  min-width: 135px;
  text-align: center;
  font-weight: 500;
  line-height: 1.5em;
  -webkit-box-shadow: 0 10px 40px 0 rgba(59, 20, 0, 0.1);
  box-shadow: 0 10px 40px 0 rgba(59, 20, 0, 0.1);
  position: relative;
}
.cs-toolbox_btn span {
  position: relative;
  z-index: 1;
}
.cs-site_header_full_width .container {
  max-width: 100%;
  padding: 0 50px;
}
.cs-site_header_style1 {
  border-bottom: 1px solid #1a188d;
}
site_branding .cs-site_header_style1 .cs-main_header_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cs-site_header_style1 .cs-nav + .cs-header_toolbox.cs-center {
  margin-left: 35px;
}
.cs-site_header_style1 .cs-nav .cs-nav_list > li.current-menu-item > a:before {
  bottom: -4px;
  background-color: #fff;
}
.cs-main_header {
  width: 100%;
  text-align: center;
  padding: 20px 0 0 0;
}
@media screen and (max-width: 1199px) {
  .cs-main_header .container {
    max-width: 100%;
  }
  .cs-site_header.cs-style1 .cs-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.cs-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cs-site_branding {
  display: inline-block;
}
.cs-site_branding img {
  height: 200px;
}
@media screen and (min-width: 1200px) {
  .cs-main_header {
    position: relative;
  }
  .cs-main_header .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
  }
  .cs-main_header_center,
  .cs-top_header_center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .cs-site_header.cs-style1 .cs-main_header_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  .cs-site_header.cs-style1 .cs-main_header_left {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 1360px) {
  .cs-site-branding {
    max-width: 190px;
  }
  .cs-site_header_full_width .container {
    max-width: 100%;
    padding: 0 15px;
  }
}
@media screen and (max-width: 991px) {
  .cs-site_header .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .cs-site-branding {
    max-width: 150px;
  }
  .cs-header_toolbox {
    margin-right: 44px;
  }
}
@media screen and (max-width: 450px) {
  .cs-toolbox > a:not(:first-child) {
    margin-left: 0;
  }
}
.cs-dark {
  background-color: #003057;
  color: #d9d9d9;
}
.cs-dark .cs-font_color {
  color: #000000;
}

/**404 Page Css******/
.box {
  width: 350px;
  height: 100%;
  max-height: 600px;
  min-height: 450px;
  background: #003057;
  border-radius: 20px;
  padding: 30px 50px;
  position: relative;
}
.box .box__ghost {
  padding: 15px 25px 25px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
}
.box .box__ghost .symbol:first-child {
  opacity: 0.2;
  animation: shine 4s ease-in-out 3s infinite;
}
.box .box__ghost .symbol:first-child:after,
.box .box__ghost .symbol:first-child:before {
  content: "";
  width: 12px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  bottom: 65px;
  left: 0;
}
.box .box__ghost .symbol:first-child:before {
  transform: rotate(45deg);
}
.box .box__ghost .symbol:first-child:after {
  transform: rotate(-45deg);
}
.box .box__ghost .symbol:nth-child(2) {
  position: absolute;
  left: -5px;
  top: 30px;
  height: 18px;
  width: 18px;
  border: 4px solid;
  border-radius: 50%;
  border-color: #fff;
  opacity: 0.2;
  animation: shine 4s ease-in-out 1.3s infinite;
}
.box .box__ghost .symbol:nth-child(3) {
  opacity: 0.2;
  animation: shine 3s ease-in-out 0.5s infinite;
}
.box .box__ghost .symbol:nth-child(3):after,
.box .box__ghost .symbol:nth-child(3):before {
  content: "";
  width: 12px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  top: 5px;
  left: 40px;
}
.box .box__ghost .symbol:nth-child(3):before {
  transform: rotate(90deg);
}
.box .box__ghost .symbol:nth-child(3):after {
  transform: rotate(180deg);
}
.box .box__ghost .symbol:nth-child(4) {
  opacity: 0.2;
  animation: shine 6s ease-in-out 1.6s infinite;
}
.box .box__ghost .symbol:nth-child(4):after,
.box .box__ghost .symbol:nth-child(4):before {
  content: "";
  width: 15px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  top: 10px;
  right: 30px;
}
.box .box__ghost .symbol:nth-child(4):before {
  transform: rotate(45deg);
}
.box .box__ghost .symbol:nth-child(4):after {
  transform: rotate(-45deg);
}
.box .box__ghost .symbol:nth-child(5) {
  position: absolute;
  right: 5px;
  top: 40px;
  height: 12px;
  width: 12px;
  border: 3px solid;
  border-radius: 50%;
  border-color: #fff;
  opacity: 0.2;
  animation: shine 1.7s ease-in-out 7s infinite;
}
.box .box__ghost .symbol:nth-child(6) {
  opacity: 0.2;
  animation: shine 2s ease-in-out 6s infinite;
}
.box .box__ghost .symbol:nth-child(6):after,
.box .box__ghost .symbol:nth-child(6):before {
  content: "";
  width: 15px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  bottom: 65px;
  right: -5px;
}
.box .box__ghost .symbol:nth-child(6):before {
  transform: rotate(90deg);
}
.box .box__ghost .symbol:nth-child(6):after {
  transform: rotate(180deg);
}
.box .box__ghost .box__ghost-container {
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  position: relative;
  margin: 0 auto;
  animation: upndown 3s ease-in-out infinite;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes {
  position: absolute;
  left: 50%;
  top: 45%;
  height: 12px;
  width: 70px;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-left {
  width: 12px;
  height: 12px;
  background: #003057;
  border-radius: 50%;
  margin: 0 10px;
  position: absolute;
  left: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-right {
  width: 12px;
  height: 12px;
  background: #003057;
  border-radius: 50%;
  margin: 0 10px;
  position: absolute;
  right: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom div {
  flex-grow: 1;
  position: relative;
  top: -10px;
  height: 20px;
  border-radius: 100%;
  background-color: #fff;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom div:nth-child(2n) {
  top: -12px;
  margin: 0 0;
  border-top: 15px solid #003057;
  background: 0 0;
}
.box .box__ghost .box__ghost-shadow {
  height: 20px;
  box-shadow: 0 50px 15px 5px #003082;
  border-radius: 50%;
  margin: 0 auto;
  animation: smallnbig 3s ease-in-out infinite;
}
.box .box__description {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.box .box__description .box__description-container {
  color: #fff;
  text-align: center;
  width: 200px;
  font-size: 16px;
  margin: 0 auto;
}
.box .box__description .box__description-container .box__description-title {
  font-size: 24px;
  letter-spacing: 0.5px;
}
.box .box__description .box__description-container .box__description-text {
  color: #8c8aa7;
  line-height: 20px;
  margin-top: 20px;
}
.box .box__description .box__button {
  display: block;
  position: relative;
  background: #ff5e65;
  border: 1px solid transparent;
  border-radius: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  line-height: 50px;
  font-size: 18px;
  padding: 0 70px;
  white-space: nowrap;
  margin-top: 25px;
  transition: background 0.5s ease;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.box .box__description .box__button:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100px;
  background: #fff;
  bottom: -25px;
  left: 0;
  border: 2px solid #fff;
  transform: translateX(-50px) rotate(45deg);
  transition: transform 0.5s ease;
}
.box .box__description .box__button:hover {
  background: #003057;
  border-color: #fff;
}
.box .box__description .box__button:hover:before {
  transform: translateX(250px) rotate(45deg);
}

.navbar-collapse.pull-right.collapse {
  background: #1E2731;
}

.navbar-collapse.pull-right.collapse.show {
  background: #1E2731;
  position: fixed;
  top: 100px;
  width: 100%;
  text-align: center;
  padding: 20px 10px 40px;
}
.navbar-collapse.pull-right.collapse.show .connected-to-address {
  display: block;
  text-align: center !important;
  margin: 0 auto 20px;
}
.navbar-collapse.pull-right.collapse.show  .d-flex.align-items-lg-center.mt-3.mt-lg-0 {
  display: block !important;
  text-align: center !important;
}
.navbar-collapse.pull-right.collapse.show  .navbar-nav.margin-right{
  margin-right: 0px !important;
}

@keyframes upndown {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(5px);
  }
}
@keyframes smallnbig {
  0% {
    width: 90px;
  }
  50% {
    width: 100px;
  }
  100% {
    width: 90px;
  }
}
@keyframes shine {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}
.flex-shrink-0 .img-fluid.img-responsive {
  max-width: 180px;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 10px;
}
.cs-dark .darkText {
  color: #ffffff;
}
.d-flex.text-black {
  align-items: center;
  text-align: center;
}
#redeemUserPop {
  display: block;
  padding-right: 15px;
  background-color: rgba(0, 0, 0, 0.5);
}
.cs-dark .modal-title {
  color: #000000;
}
button.close {
  border: none;
  background: no-repeat;
  color: #000;
  font-size: 28px;
}
.modal {
  text-align: center;
}
.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-width: 450px;
}
.badge.badge-info {
  background: #17a2b8;
  margin: 4px 0 6px 0;
  font-size: 14px;
  padding: 5px 15px;
}
.card {
  background-color: transparent;
  border: none;
  color: #ffffff;
}
.redeem.btn.btn-wagyu {
  margin-top: 40px;
}
.cs-dark #redeemUserPop input.form-control {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.spinner-container {
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.spinner-container div {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
}
.landing {
  height: 100vh;
}
.spinner-container.popup-box {
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
a.cs-site_branding.cs-accent_color {
  margin-top: 50px;
}

.cs-dark .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}
.cs-dark .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.cs-dark .form-control::-ms-input-placeholder {
  color: #ffffff;
}
.cs-dark .cs-section_title {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}
.cs-dark .form-control {
  background: transparent;
  border-bottom: 1px solid #ffffff !important;
  color: #ffffff;
  border: none;
  border-radius: 0;
  font-size: 30px;
  line-height: 130%;
}

.custom-control-input {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border: 1px solid #b79b6c;
}
.custom-control.custom-checkbox {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.custom-control.custom-checkbox label {
  color: #ffffff;
  font-size: 22px;
  display: inline-block;
}
.custom-control.custom-checkbox Button {
  color: #ffffff;
  text-decoration: none;
  padding: 0;
  font-weight: 900;
}
.custom-control.custom-checkbox Button:hover {
  color: #b79b6c;
  text-decoration: none;
}
.btn-wagyu {
  color: #ffffff;
  background-color: #b79b6c;
  text-transform: uppercase;
  padding: 15px 80px 20px;
  border-radius: 100px;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: center;
  transition: all 0.1s ease;
}
.btn-wagyu:hover,
.btn-wagyu:focus,
.btn-wagyu:focus-within,
.btn-wagyu:focus-visible,
.btn-wagyu:visited {
  background-color: #a17a38;
  border: 1px solid #a17a38;
  color: #ffffff;
}
.btn-wagyu:active {
  background-color: #a17a38;
  border: 1px solid #a17a38;
  filter: drop-shadow(0 0 0 #15111a);
  transform: translate(0, 10px);
}
.btn-wagyu:disabled {
  color: #ffffff;
  background-color: #b79b6c;
  opacity: 0.6;
}
.card-body.thankbox {
  text-align: center;
}
.thanksicon {
  text-align: center !important;
  display: inline-block;
  padding: 10px 0 25px;
}
.thanksicon svg {
  max-width: 100px;
  max-height: 100px;
  text-align: center;
  display: block;
}
.cs-registerText font {
  display: block;
  font-size: 22px;
  line-height: 130%;
  margin-top: 10px;
}
.cs-dark .thankbox .cs-section_title {
  font-size: 80px;
  text-transform: uppercase;
  line-height: 130%;
  margin-bottom: 30px;
}
.cs-registerText {
  font-size: 40px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
}
.thankbox .cs-eventText {
  font-size: 30px;
  text-transform: uppercase;
  color: #b79b6c;
  line-height: 38px;
  font-weight: 700;
}
.userQrcode .qrImage {
  height: 100px;
  width: 100px;
}
.userReferralCode {
  color: #ffffff;
}

.section {
  max-width: 1100px;
  margin: 0 auto;
}

.spacebox {
  min-height: 60px;
}

.headerSection .headerBox .heading {
  font-size: 80px;
  line-height: 130%;
  text-align: center;
}
.headerSection .headerBox .heading-date {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 130%;
  text-align: center;
  background: linear-gradient(
    90deg,
    #9d7f5b 0.61%,
    #b09471 40.61%,
    #c2a57d 64.61%,
    #e5c794 100.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.productSection {
  background: #fff;
  color: #003057;
}
.productSection .section-title {
  font-size: 45px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  color: #003057;
}
.productSection .section-description {
  font-size: 30px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.06em;
  text-align: center;
  color: #003057;
}
.productSteps .productSteps-text .section-title {
  font-size: 80px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.06em;
  text-align: center;
}
.productSteps .productSteps-text .steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 100px;
  background: #003057;
  box-shadow: 4px 4px 30px rgba(28, 29, 31, 0.3);
  border-radius: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.footerPadding {
  padding-bottom: 80px;
}
.productSteps .productSteps-text .steps .step-heading {
  font-size: 60px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: center;
  min-width: 267px;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    #9d7f5b 0.61%,
    #b09471 40.61%,
    #c2a57d 64.61%,
    #e5c794 100.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.productSteps .productSteps-text .steps .step-content font {
  font-size: 22px;
  display: block;
  margin-top: 15px;
  margin-left: 40px;
}
.productSteps .productSteps-text .steps .step-content {
  font-size: 40px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.06em;
  text-align: left;
}

.contactSection {
  background: #001f36;
  color: #ffffff;
}
.contactSection .section-title {
  font-size: 45px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
}
.contactSection .section-description {
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.06em;
  text-align: center;
}
.registerForm {
  max-width: 700px;
  margin: 0 auto;
}

#redeemUserPop .modal-content {
  background: #003057;
  color: #ffffff;
}
#redeemUserPop .modal-content .modal-title,
#redeemUserPop .modal-content button.close {
  color: #ffffff;
}
.cs-dark #redeemUserPop .modal-content .cs-font_color {
  color: #ffffff;
}
#redeemUserPop .modal-content button.btn.btn-modal-wagyu {
  color: #ffffff;
  background-color: #b79b6c;
  text-transform: uppercase;
  padding: 10px 40px;
  border: 1px solid #b79b6c;
  border-radius: 100px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: center;
  transition: all 0.1s ease;
  outline: none;
}
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:hover,
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:focus,
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:focus-within,
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:focus-visible,
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:visited {
  background-color: #a17a38;
  border: 1px solid #a17a38;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
#redeemUserPop .modal-content button.btn.btn-modal-wagyu:active {
  background-color: #a17a38;
  border: 1px solid #a17a38;
  filter: drop-shadow(0 0 0 #15111a);
  transform: translate(0, 10px);
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
}

.unsubscribeBox {
  margin: 200px 0;
}
.unsubscribeBtnBox {
  margin-bottom: 200px;
}

@media screen and (max-width: 767px) {
  .cs-site_branding img {
    height: 64px !important;
  }
  .row.d-flex.justify-content-center.align-items-center.py-5 {
    padding: 20px 0 !important;
  }
  .cs-site_branding.cs-accent_color {
    margin-top: 60px !important;
  }
  .cs-footer .cs-site_branding.cs-accent_color {
    margin-top: 20px !important;
  }
  .cs-site_header.cs-style1 .cs-main_header_in,
  .cs-site_header.cs-style1 .cs-top_header_in {
    height: auto !important;
  }
  .cs-main_header {
    padding: 0px;
  }
  .modal-dialog {
    min-width: 320px;
  }
  .spacebox {
    min-height: 20px;
  }
  .card-body.p-4 .d-flex.text-black {
    display: inline-block !important;
    width: 100% !important;
  }
  .card-body.p-4 .flex-shrink-0 {
    margin: 0 auto;
    text-align: center;
  }
  .card-body.p-4 .flex-grow-1.ms-3 {
    margin: 10px auto 0;
    text-align: center;
  }
  .card-body.p-4 .d-flex.pt-1 {
    display: block !important;
    margin: 0 auto;
    text-align: center !important;
  }
  .thankbox .cs-eventText {
    font-size: 22px;
    line-height: 30px;
  }
  .headerSection .headerBox .heading {
    font-size: 25px;
    margin-bottom: 0;
  }
  .headerSection .headerBox .heading-date {
    font-size: 20px;
  }
  .productSection .section-title {
    font-size: 18px;
    max-width: 90%;
    margin: 0 auto 10px;
  }
  .productSection .section-description {
    font-size: 15px;
    max-width: 90%;
    margin: 0 auto 10px;
  }
  .productSteps .productSteps-text .section-title {
    font-size: 25px;
  }
  .productSteps .productSteps-text .steps {
    display: block;
    text-align: center;
    padding: 25px 45px;
  }
  .productSteps .productSteps-text .steps .step-heading {
    font-size: 22px;
    min-width: 100%;
    display: block;
    line-height: 38px;
    text-align: center;
    margin-bottom: 10px;
  }
  .productSteps .productSteps-text .steps .step-content {
    font-size: 15px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.06em;
    text-align: center;
    display: block;
  }
  .contactSection .section-title {
    font-size: 20px;
    line-height: 30px;
  }
  .registerForm {
    max-width: 100%;
  }
  .cs-dark .form-control {
    font-size: 15px;
  }
  .custom-control.custom-checkbox label {
    font-size: 12px;
  }
  .custom-control.custom-checkbox label .btn.btn-link {
    font-size: 12px;
  }
  .btn-wagyu {
    padding: 6px 40px;
    font-size: 18px;
  }
  .thanksBox-text {
    margin-top: 30px;
  }
  .cs-dark .thankbox .cs-section_title {
    font-size: 25px;
  }
  .cs-registerText {
    font-size: 15px;
  }
  .cs-registerText font {
    font-size: 11px;
  }
  #redeemUserPop .modal-content button.btn.btn-danger,
  #redeemUserPop .modal-content button.btn.btn-primary {
    padding: 7px 20px !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .productSteps .productSteps-text .steps .step-content font {
    font-size: 11px;
    margin-left: 0px;
    margin-top: 15px;
  }
  .contactBox-text {
    padding: 30px 20px;
  }
  .checkboxMobile.mb-4 {
    margin-bottom: 1rem !important;
  }
  .custom_col,
  .socialMenu,
  .social-items,
  .footerLinks,
  .link-item {
    text-align: center !important;
  }
  .custom-control-input {
    height: 15px !important;
    width: 15px !important;
    margin-right: 3px !important;
  }
  input[type="checkbox"]:before {
    width: 15px !important;
    height: 15px !important;
  }
  .footerPadding {
    padding-bottom: 50px !important;
  }
  .cs-footerBottom {
    margin-bottom: 80px !important;
  }
  .unsubscribeBox {
    margin: 100px 0 !important;
  }
  .unsubscribeBtnBox {
    margin-bottom: 100px !important;
  }
  input[type="checkbox"]:checked::after {
    width: 4px !important;
    height: 10px !important;
    top: 2px !important;
    left: 6px !important;
}
}

/* input checkbox css */
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border: 1px solid #fff;

  border-radius: 0px;
  background-color: #001f36;
  padding: 1px;
}
input[type="checkbox"]:checked::before {
  background-color: #001f36;
}

input[type="checkbox"]:checked::after {
  content: "";
  display: block;
  width: 7px;
  height: 13px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 7px;
}

/* input checkbox css ended */
.custom-control.custom-checkbox label a {
  font-weight: 700;
}
.copyright {
  color: #66839a;
  font-size: 14px;
  text-align: left;
}
.footerLinks,
.link-item {
  list-style: none;
  text-align: right;
  margin: 0px;
  padding: 0px;
}
.footerLinks .link-item .item {
  color: #66839a;
  font-size: 14px;
  text-decoration: underline #fff;
}
.socialMenu,
.social-items {
  list-style: none;
  text-align: center;
  display: inline-block;
  margin: 0px;
  padding: 0px;
}
.socialMenu .social-items svg {
  height: 32px;
  width: 32px;
  fill: #ffffff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 6px;
  margin: 0 5px 0 5px;
}
.cs-footerBottom {
  vertical-align: middle;
  align-items: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 375px) {
  .thankbox .cs-eventText {
    font-size: 20px;
    line-height: 28px;
  }
  .headerSection .headerBox .heading {
    font-size: 22px;
    margin-bottom: 0;
  }
  .headerSection .headerBox .heading-date {
    font-size: 18px;
  }
  .productSection .section-title {
    font-size: 15px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .productSection .section-description {
    font-size: 15px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .productSteps .productSteps-text .section-title {
    font-size: 22px;
  }
  .productSteps .productSteps-text .steps {
    display: block;
    text-align: center;
    padding: 25px 45px;
  }
  .productSteps .productSteps-text .steps .step-heading {
    font-size: 20px;
    min-width: 100%;
    display: block;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
  .productSteps .productSteps-text .steps .step-content {
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.06em;
    text-align: center;
    display: block;
  }
  .contactSection .section-title {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-dark .form-control {
    font-size: 14px;
  }
  .custom-control.custom-checkbox label {
    font-size: 10px;
  }
  .custom-control.custom-checkbox label .btn.btn-link {
    font-size: 12px;
  }
  .btn-wagyu {
    padding: 6px 30px;
    font-size: 16px;
  }
  .thanksBox-text {
    margin-top: 24px;
  }
  .cs-dark .thankbox .cs-section_title {
    font-size: 22px;
  }
  .cs-registerText {
    font-size: 14px;
  }
  .cs-registerText font {
    font-size: 10px;
  }
  #redeemUserPop .modal-content button.btn.btn-danger,
  #redeemUserPop .modal-content button.btn.btn-primary {
    padding: 7px 15px !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .productSteps .productSteps-text .steps .step-content font {
    font-size: 10px;
    margin-left: 0px;
    margin-top: 15px;
  }
  .contactBox-text {
    padding: 20px 15px;
  }
  .checkboxMobile.mb-4 {
    margin-bottom: 1rem !important;
  }
  .custom_col,
  .socialMenu,
  .social-items,
  .footerLinks,
  .link-item {
    text-align: center !important;
  }
  .custom-control-input {
    height: 15px !important;
    width: 15px !important;
    margin-right: 3px !important;
  }
  input[type="checkbox"]:before {
    width: 15px !important;
    height: 15px !important;
  }
  .footerPadding {
    padding-bottom: 40px !important;
  }
  .cs-footerBottom {
    margin-bottom: 60px !important;
  }
  .unsubscribeBox {
    margin: 80px 0 !important;
  }
  .unsubscribeBtnBox {
    margin-bottom: 80px !important;
  }
  input[type="checkbox"]:checked::after {
    width: 4px !important;
    height: 10px !important;
    top: 2px !important;
    left: 6px !important;
  }
  .card-body.thankbox.p-4.p-md-5 {
    padding: 10px !important;
  }
}

.desktopContent{
  display: block;
}
.mobileContent{
  display: none;
}

@media screen and (max-width: 767px) {
  .desktopContent{
    display: none !important;
  }
  .mobileContent{
    display: block !important;
  }
}

@media screen and (max-width: 375px) {
  .desktopContent{
    display: none !important;
  }
  .mobileContent{
    display: block !important;
  }
}

@media (min-width:768px) and (max-width:1024px){
  .headerSection .headerBox .heading, .productSteps .productSteps-text .section-title, .cs-dark .thankbox .cs-section_title {
    font-size: 60px !important;
  }
  .headerSection .headerBox .heading-date {
    font-size: 45px !important;
  }
  .spacebox {
    min-height: 40px !important;
  }
  .productSection .section-title, .cs-registerText {
    font-size: 30px !important;
  }
  .productSection .section-description{
    font-size: 22px !important;
  }
  .productSteps .productSteps-text .steps {
    padding: 20px 40px !important;
  }
  .step-content {
    font-size: 25px !important;
  }
  .productSteps .productSteps-text .steps .step-heading{
    font-size: 40px !important;
  }
  .contactSection .section-title {
    font-size: 32px !important;
  }
  .productSteps .productSteps-text .steps .step-content font {
    font-size: 16px !important;  
    margin-top: 10px !important;
    margin-left: 20px !important;
  }
  .registerForm {
    max-width: 600px !important;
  }
  .cs-dark .form-control {
    font-size: 22px !important;
  }
  .custom-control.custom-checkbox label {
    font-size: 18px !important;
  }
  .btn-wagyu {
    padding: 10px 60px 15px !important;
    font-size: 24px !important;
  }
  .modelTitle.termsCondition, .modelTitle.privacyPolicy {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .long-text {
    padding-top: 60px !important;
  }
  .modelBody.termsCondition, .modelBody.privacyPolicy {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .modelBody.termsCondition table {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .modalSpacer {
    height: 40px !important;
  }
  .modalSpacer2 {
    height: 80px !important;
  }
}




.text-White{
  color:#fff;
}
.connected-to-heading {
  font-weight: 700;
  color: #ef981d;
}
button.connected-to-address {
  background: none;
  border: none;
  margin-right: 20px;
  font-weight: 500;
  cursor: pointer;
}
.container-fluid.no-margin-padding {
  margin: 0;
  padding: 0;
}
.collection_banner {
  height: auto;
  margin-top: 50px;
  position: relative;
}
nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  height: 100px;
  border-bottom: 1px solid #7e96a9;
  z-index: 9;
  background-color: #1E2731 !important;
}
.collection_banner .desktopBannerClass {
  width: 100%;
  min-height: 300px;
  max-height: 450px;
  object-fit: cover;
}

.collection_banner .daLogoClass {
  max-width: 350px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.mintevent {
  background: #0d171e;
  border: 2.5px solid #ef981d;
  border-radius: 20px;
  max-width: 420px;
  margin: auto;
  padding: 10px 40px;
  width: 100%;
  display: inline-block;
}
.start_btn, .start_btn span {
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
}
.start_btn {
  background: linear-gradient(180deg,#ef981d,#f9bf50);
  border: 2px solid #bd712e;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  text-shadow: 0 2px 4px rgb(0 0 0 / 40%);
  padding: 8px 30px;
}
.start_btn span {
  background: #ff364a;
  border-radius: 5px;
  font-weight: 800;
  text-align: center;
  padding: 5px 40px;
  margin-left: 20px;
}
.mintevent h4 {
  font-weight: 700;
  font-size: 36px;
  margin: calc(2rem - 0.14286em) 0 1rem;
  color:#ffffff;
}
.da_img {
  max-height: 300px;
  max-width: 300px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}
.collection_status {
  text-align: center;
  padding: 0;
  max-width: 1000px;
  margin: auto;
}
.collection_status li {
  display: inline-block;
  border-left: 1px solid #aaa;
  width: 25%;
  padding: 10px;
}
.collection_status li h4 {
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  line-height: 30px;
}
.collection_status li p {
  font-weight: 400;
  font-size: 20px;
  color: #939393;
  margin-bottom: 0;
}
.collection_status li:last-child {
  border-right: 1px solid #aaa;
}
.hunter-market {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}
.collection_description p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  max-width: 900px;
  margin: auto;
  line-height: 24px;
}
.amount {
  margin-top: 30px;
}
.da_img .nft_img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
}
.mintprice {
  border: 1px solid #ef981d;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  padding: 10px;
}
.mintprice .nft_hunter_Logo {
  max-width: 25px;
}
.amount h5 {
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 0;
}
.amount h5, .amount p {
  color: #fff;
  font-weight: 400;
}
.qt_selector button {
  background: linear-gradient(180deg,#ef981d,#f9bf50);
  width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.qt_selector button, .qt_selector input[type="text"] {
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: #fff;
  height: 50px;
  border: 1px solid #ef981d;
}
.qt_selector button {
  background: linear-gradient(180deg,#ef981d,#f9bf50);
  width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.product_sales_box {
  text-align: center;
  margin: 20px auto 0;
  padding: 40px 0 0 0;
}
.qt_selector input[type="text"] {
  background: #0d171e;
  line-height: 42px;
  width: 70px;
  margin: 0 10px;
}
.mint_btn .buy-nft-btn {
  background: linear-gradient(180deg,#ef981d 26.56%,#f9bf50);
  border: 1px solid #394956 !important;
  border-radius: 10px !important;
  padding: 6px 15px;
  font-weight: 700;
  font-size: 24px;
  color: #fff !important;
  width: 100%;
  max-width: 220px;
  margin: 0 !important;
}
button:disabled{
  opacity: 0.65;
}
.modal__backdrop {
  background: rgba(0,0,0,.65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.modal__container {
  background: #fff;
  border-radius: 5px;
  margin: 50px auto;
  padding: 30px;
  max-width: 560px;
  position: relative;
  border: 5px solid #ef981d;
  box-sizing: border-box;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
button.custom_close:disabled {
  opacity: 0.5;
cursor: pointer;
}
button.custom_close {
  border: 0;
  background: #ef981d;
  color: #fff;
  border-radius: 100%;
  line-height: inherit;
  width: 30px;
  height: 30px;
  font-size: 17px;
  position: absolute;
  right: -15px;
  top: -15px;
opacity: 1;
cursor: pointer;
}
.modal__container .minting {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}
.modal__container .minting li {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px dotted #ef981d;
}
.modal__container .minting li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}
.modal__container .minting li img {
  margin-right: 20px;
  width: 50px;
}
.modal__container .minting li p:last-child {
  margin-bottom: 0;
}
.modal__container .minting li p:first-child {
  margin-top: 0;
}
.loadingImage {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.modal__container{
  background-color: #293641 !important;
  color: #ffffff;
}
#navbarCollapse .navbar-nav.margin-right {
  margin-right: 40px;
}

#navbarCollapse .navbar-nav .nav-link {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff !important;
}

/**********************************************************************************************/
/********************************  Claim Page CSS Starts   ************************************/
/**********************************************************************************************/
.claim-content-div {
  margin-top: 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.claim-content-div .hntr-boxed {
  background: radial-gradient(circle, #5f7b90 0%, #0d1114 100%);
  float: left;
  width: 100%;
  padding: 20px 0 40px 0;
  margin: 180px auto 0;
  border-radius: 20px;
  border: 3px solid #FA9F09;
}
.claim-content-div .content-h2 {
  color: white;
  font-size: 35px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.claim-content-div .hunter-Logo-img {
  max-width: 70px;
}
.claim-content-div .content-p {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  text-align: center;
  color: #fff;
}
.claim-content-div .card {
  border-radius: 10px !important;
  margin: 1rem;
  padding: 15px;
}
.claim-content-div .tier_group.card-group {
  max-width: 350px;
  margin: 0 auto;
}
.claim-content-div .card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-around;
  border-bottom: 0.5px solid gray;
}
.claim-content-div .hntr-boxed .card {
  background: #212b32 !important;
  border: 1px solid #fa9f09;
}
.claim-content-div .tier_body.card-body {
  align-items: center;
}
.claim-content-div .hunter-Logo-img-small {
  max-width: 40px;
}
.claim-content-div .card-text:last-child {
  margin-bottom: 0;
}
.claim-content-div .tier_heading.card-text {
  color: #fff;
}
.claim-content-div .tier_heading {
  font-size: 20px;
}
.claim-content-div .card-bottom {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
}

.claim-content-div .tier_token-btn.status_btn.custom-btn, 
.claim-content-div .tier_token-btn.claim_btn.custom-btn{
  border: 1.5px solid #FA9F09 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  padding: 6px 15px !important;
  font-size: 16px !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  text-decoration: none;
  background-color: transparent !important;
}
.claim-content-div .tier_token-btn.claim_btn.btn.btn-secondary {
  margin: 0 auto;
}
.claim-content-div .card-bottom.mt-4.importtext {
  color: #ffffff;
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 15px;
}
.claim-content-div .importLink {
  color: #ef981d;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
.claim-content-div select.tier {
  width: 100%;
  float: left;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  background: #fff;
}
.claim-content-div .card-bottom {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
}
.claim-content-div .btn.btn-secondary {
  padding: 6px 20px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 18px;
}
.claim-content-div .card-bottom.custombtn {
  display: inline-block;
}
/**********************************************************************************************/
/********************************  Claim Page CSS Ends   **************************************/
/**********************************************************************************************/



/**********************************************************************************************/
/********************************  Staking Page CSS Starts   **********************************/
/**********************************************************************************************/
.stakingpool-content-div.staking-content-div {
  max-width: 1000px;
}
.staking-content-div {
  margin-top: 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.staking-content-div .hntr-boxed {
  background: radial-gradient(circle, #5f7b90 0%, #0d1114 100%);
  float: left;
  width: 100%;
  padding: 20px 0 40px 0;
  margin: 180px auto 0;
  border-radius: 20px;
  border: 3px solid #FA9F09;
}
.staking-content-div .content-h2 {
  color: white;
  font-size: 35px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.staking-content-div .hunter-Logo-img {
  max-width: 70px;
}
.staking-content-div .content-p {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  text-align: center;
  color: #fff;
}
.staking-content-div .card {
  border-radius: 10px !important;
  margin: 0px 1rem;
  padding: 0px;
}
.staking-content-div .staking_group.card-group {
  max-width: 350px;
  margin: 0 auto;
}
.staking-content-div .card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-around;
}
.staking-content-div .card-align {
  align-items: center;
  align-self: center;
  margin: 0% auto;
}
.staking-content-div .hntr-boxed .card {
  background: #212b32 !important;
  border: 1px solid #fa9f09 !important;
}
.staking-content-div .hunter-Logo-img-small {
  max-width: 40px;
}
.staking-content-div .card-text:last-child {
  margin-bottom: 0;
}
.staking-content-div .staking_heading.card-text {
  color: #fff;
}
.staking-content-div .staking_heading {
  font-size: 20px;
}
.staking-content-div .card-bottom {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
}

.input-max,
.input-confirm,
.staking-content-div .token-btn.pancakelink,
.staking-content-div .btn-DA-theme.approve_deposit_btn.approve-lp,
.staking-content-div button.btn-DA-theme.approve_deposit_btn.deposit-lp{
  border: 1.5px solid #FA9F09 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  padding: 6px 15px !important;
  font-size: 16px !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  text-decoration: none;
  background-color: transparent !important;
}
.staking-content-div .card-bottom.mt-4.importtext {
  color: #ffffff;
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 15px;
}
.staking-content-div .importLink {
  color: #ef981d;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
.staking-content-div select.tier {
  width: 100%;
  float: left;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0;
  background: #fff;
}
.staking-content-div .card-bottom {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
}
.staking-content-div .btn.btn-secondary {
  padding: 6px 20px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 18px;
}
.staking-content-div .card-bottom.custombtn {
  display: inline-block;
}
.staking-content-div .card-align.p-1.card-text,
.staking-content-div .card-text {
  color: #ffffff;
}

.card-container.pool_container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.pool_container .card-group {
  display: block;
}
.pool_container .card.pool1-card.pool_custom_box.card {
  display: inline-block;
  min-height: 305px;
}
.staking-content-div .card-body.pools-card {
  border-bottom: 0.5px solid gray;
  display: grid;
  border: none;
}
.pool1-card.card {
  min-width: 400px;
  max-width: 400px;
  padding: 5px 30px;
}
.card-icon {
  padding: 1rem;
}
.pool_section .btn-DA-theme.approve_deposit_btn {
  min-width: 160px;
  max-width: 160px;
}
.pricecolor {
  color: #FA9F09;
}
/**********************************************************************************************/
/********************************  Staking Page CSS Ends   ************************************/
/**********************************************************************************************/


/**********************************************************************************************/
/**********************************  Popup CSS Starts   ***************************************/
/**********************************************************************************************/
.popup-bg {
	position: fixed;
	z-index: 999;
	background-color: #4b4b4d8f;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader_popup-box{
	max-width: 450px;
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
}

.checkiconDefault {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(45deg);
	height: 30px;
	width: 15px;
	border-bottom: 5px solid #e0e0e0;
	border-right: 5px solid #e0e0e0;
	margin-left: 30px;
}

.checkiconCompleted {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(45deg);
	height: 30px;
	width: 15px;
	border-bottom: 5px solid #78b13f;
	border-right: 5px solid #78b13f;
	margin-left: 15px;
}

button.closeBtn.btn-main {
    width: 90%;
    margin: 0 auto;
    background: #FA9F09;
    color: #fff;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid #FA9F09;
	cursor: pointer;
}
button.closeBtn.btn-main:disabled , button.closeBtn.btn-main:disabled:hover{
    background: #1a1714;
    color: #ffffff;
    border: 2px solid #1a1714;
}

button.closeBtn.btn-main:hover {
    background: #ffffff;
    color: #FA9F09;
    border: 2px solid #FA9F09;
}

.customDisplayPopup {
	margin-bottom: 20px;
	text-align: left;
	align-self: center;
	vertical-align: middle;
}

.errorIcon {
	position: relative;
	width: 32px;
	height: 32px;
}

.errorIcon:before, .errorIcon:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 5px;
	background-color: #ff0000;
}

.errorIcon:before {
	transform: rotate(45deg);
}

.errorIcon:after {
	transform: rotate(-45deg);
}

.popupHeading {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
}

.loader_popup-box h2 {
    font-size: 24px;
    font-weight: 600;
}

.popupText {
	font-size: 15px;
	line-height: 20px !important;
	margin: 5px 0 0 0;
	font-weight: 400;
}

.icontxtDisplayPopup-col3 {
	align-self: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icontxtDisplayPopup {
	align-self: center;
	vertical-align: middle;
}

.clockloader {
	--clock-color: rgb(45, 113, 144);
	--clock-width: 3rem;
	--clock-radius: calc(var(--clock-width) / 2);
	--clock-minute-length: calc(var(--clock-width) * 0.4);
	--clock-hour-length: calc(var(--clock-width) * 0.2);
	--clock-thickness: 0.2rem;

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--clock-width);
	height: var(--clock-width);
	border: 3px solid var(--clock-color);
	border-radius: 50%;
}

.clockloader::after {
	top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
	height: var(--clock-hour-length);
	animation-duration: 15s;
}

.clockloader::before {
	height: var(--clock-minute-length) !important;
	animation-duration: 2s !important;
}

.clockloader::before,
::after {
	position: absolute;
	content: "";
	top: calc(var(--clock-radius) * 0.25);
	width: var(--clock-thickness);
	background: var(--clock-color);
	border-radius: 10px;
	transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
	animation: spin infinite linear;
}

@keyframes spin {
	to {
		transform: rotate(1turn);
	}
}
span.error.popupText {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #ff0000;
    padding: 7px 5px;
    border-radius: 5px;
    background: rgba(255,0,0, 0.5);
    color: #fff;
}

.sweet-alert {
  background-color: #293641 !important;
  color: #ffffff;
  border: 2.5px solid #ef981d;
}
.sweet-alert .btn.btn-lg.btn-primary {
  background: linear-gradient(180deg,#ef981d 26.56%,#f9bf50);
  border: 1px solid #394956 !important;
  border-radius: 10px !important;
  padding: 6px 15px;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
  font-size: 24px;
  color: #fff !important;
  width: 100%;
  max-width: 100px;
  margin: 0 !important;
  box-shadow: none !important;
}


.white-bg.card {
  background: #ffffff !important;
  color: #212529;
}

.white-bg.card .card-body {
  border: none;
  align-items: center;
  align-self: center;
}

.white-bg.card .card-body .card-text, .white-bg.card .card-body .card-text:last-child {
  align-items: center;
  align-self: center;
  display: inline-block;
  padding: 0px 10px;
  margin: 0px;
}

.approve-lp {
  font-size: 14px;
  color: rgb(109, 108, 108);
  margin: auto;
  flex-wrap: nowrap !important;
}

.pool1-card {
  padding: 5px 30px;
  min-width: 50px;
  border-bottom: none;
}

.poolpopup-box {
  position: fixed;
  background: #000000c7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.pool-box {
  background: radial-gradient(circle, #5f7b90 0%, #0d1114 100%);
  border: 1px solid #fa9f09;
}

.pool-box {
  position: relative;
  width: 50%;
  max-width: 645px;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(111vh - 85vh - 20px);
  /* background: #fff; */
  border-radius: 4px;
  padding: 20px;
  /* border: 1px solid #999; */
  overflow: auto;
}

.close-popup {
  position: absolute;
  top: 1%;
  right: 1%;
  content: 'x';
  cursor: pointer;
  background: #000000;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  font-size: 20px;
}

.deposit-lp {
  margin: auto;
  padding-bottom: 5px;
  background-color: transparent !important;
  color: #000 !important;
  border: 2px solid !important;
  border-color: transparent !important;
  width: 90%;
}

.withdrwa-lp {
  margin: auto;
  padding-bottom: 5px;
  background-color: transparent !important;
  color: #000 !important;
  border: 2px solid !important;
  border-color: transparent !important;
}

.deposit-lp:hover {
  border: 2px solid #ef981d !important;
}

.withdrwa-lp:hover {
  border: 2px solid #ef981d !important;
}

.harvest-heading {
  padding: 1rem;
}

.input-harvest {
  padding: 1rem;
}

.HNTR-LP {
  margin: auto;
}

.available-HNTR {
  color: #ffffff;
}

.popAmtcolor {
  color: #ef981d;
}


.input-max:active, .input-max:hover {
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  background-color: #ef981d !important;
}

.input-confirm:enabled:hover, .input-confirm:hover {
  color: #ef981d !important;
  border: 2px solid #ef981d !important;
  background-color: #ffffff !important;
}

.input-confirm:active {
  color: #ffffff !important;
  border: 2px solid #ef981d !important;
  border-radius: 5px !important;
  background-color: #ef981d;
}

.input-confirm:enabled {
  color: #ffffff !important;
  border: 2px solid #ef981d !important;
  border-radius: 5px !important;
  background-color: #ef981d !important;
}

.btn-hntr {
  color: #ef981d !important;
  border-color: #ef981d !important;
  border: 2px solid !important;
  border-radius: 2px !important;
  background-color: transparent !important;
  margin: 10px 0px;
  text-decoration: none;
  padding: 10px 0px;
  font-weight: bold;
}

.btn-hntr:hover {
  color: #fff !important;
  background-color: #ef981d !important;
  border: 2px solid !important;
  border-radius: 2px !important;
  border-color: #ef981d !important;
}
.pool1-card.card {
  min-width: 400px;
  max-width: 400px;
}

.card-bottom {
  margin: 0 auto;
  width: 100%;
}

.deposit-div {
  min-width: 50%;
}

.btn-hntr.btn {
  padding: 7px 12px;
  font-weight: 500;
  border-radius: 5px !important;
}

.btn-hntr.no-margin {
  margin: 0 auto;
}

button.btn-hntr.approve_deposit_btn {
  min-width: 120px;
  max-width: 120px;
}

.hunter-Logo-img {
  max-width: 70px;
}

.btn-DA-theme {
  background-color: #FA9F09 !important;
  font-weight: 700;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #FA9F09 !important;
  padding: 7px 20px !important;
  font-size: 1rem !important;
}

.pool_section .btn-DA-theme.approve_deposit_btn {
  min-width: 160px;
  max-width: 160px;
}

.btn-DA-theme.approve_deposit_btn:hover {
  background: #ffffff !important;
  border: 2px solid #ef981d !important;
  color: #ef981d !important;
}

.maxinput_sec .HNTR-LP {
  display: inline-block;
  margin-right: 10px;
}

.maxinput_sec {
  display: inline-block;
}

.pool_container .card.pool1-card.pool_custom_box.card {
  display: inline-block;
  min-height: 305px;
}

.pool_container .card-group {
  display: block;
}

.pool_box {
  max-width: 1000px;
  margin: 0 auto;
  background: radial-gradient(circle, #5f7b90 0%, #0d1114 100%);
  border-radius: 20px;
  padding: 20px 0 40px 0;
  border: 3px solid #FA9F09;
}

@media (min-width: 576px) {
  .pool_box .card-group>.card+.card {
      border-left: 1px solid #fa9f09;
  }
}

.token-btn.pancakelink.alertBox {
  padding: 8px 20px !important;
  color: #ffffff !important;
}

.harvest-icon {
  width: 65px;
}

.card-icon {
  padding: 1rem;
}

.pools-card {
  padding: 1.5rem 2rem;
  border-bottom: 0.5px solid gray;
  display: grid;
  border: none;
}

.input-group-text {
  border-left: none !important;
}

.readonly-input {
  border-right: none !important;
}

.select-crop {
  color: #ef981d !important;
  border-color: #ef981d !important;
  border: 2px solid !important;
  border-radius: 2px !important;
  background-color: transparent !important;
  margin: 10px 0px;
  text-decoration: none;
  padding: 10px 0px;
  font-weight: bold;
}

.select-crop:hover {
  color: #fff !important;
  background-color: #ef981d !important;
  border: 2px solid !important;
  border-radius: 2px !important;
  border-color: #ef981d !important;
}

.white-bg.card {
  background: #ffffff !important;
  color: #212529;
}

.white-bg.card .card-body {
  border: none;
  align-items: center;
  align-self: center;
}

.white-bg.card .card-body .card-text, .white-bg.card .card-body .card-text:last-child {
  align-items: center;
  align-self: center;
  display: inline-block;
  padding: 0px 10px;
  margin: 0px;
}
.card.pool1-card.pool_custom_box.card .card-bottom {
  padding: 10px 0px 0 !important; 
}
@media only screen and (max-width: 600px) {
  .content-container {
      max-width: 100%;
      padding: 1.5rem 0;
  }
  .pool1-card.card {
      min-width: 90%;
      max-width: 90%;
  }
  .pool-box {
      width: 90% !important;
  }
  button.tier_token-btn.status_btn.custom-btn.btn.btn-secondary {
      display: block !important;
      align-items: center !important;
      align-self: center !important;
      margin: 10px auto 20px;
  }
  .card-bottom {
      display: block;
      align-items: center !important;
      align-self: center;
      padding: 10px;
  }
  .hunter-Logo-img {
      max-width: 50px !important;
  }
  .content-h2 {
      color: #ffffff;
      font-size: 25px;
  }
  .hntr-boxed {
      padding: 5px 0 10px 0;
      margin: 150px auto 0;
  }
}
  
/**********************************************************************************************/
/**********************************    Popup CSS Ends     ***************************************/
/**********************************************************************************************/